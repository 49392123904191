import Button from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {StyledOtpStepBody, StyledOtpStepHeader, StyledOtpStepWrapper} from '@/features/otp-flow/components/style.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import {AcceptanceStepsConfigByService, maskPhoneNumber} from '@/features/service-acceptance-flow/utils.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'

export const OtpSmsBaseStep = () => {
    const {t} = useTranslation()
    //auth store
    const user = useAuthStore(state => state.user)
    //otp store
    const otpChangeStep = useOtpStore(state => state.changeStep)
    //const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const sendTo = useOtpStore(state => state.sendTo)
    //phone to show
    const phone = sendTo
        ? sendTo
        : coAlarmRequest?.notification_preferences?.phone_number
        ? coAlarmRequest?.notification_preferences?.phone_number
        : user?.phone

    return (
        <StyledOtpStepWrapper direction={'column'} justify={'stretch'} gap={1}>
            <StyledOtpStepHeader justify={'space-between'} align={'center'}>
                <h2>{t(AcceptanceStepsConfigByService['sms'].generic.base.title)}</h2>
                <Button variant={'tertiary'} size={'md'} onClick={() => otpChangeStep('edit', true)}>
                    {t('commons:edit')}
                </Button>
            </StyledOtpStepHeader>
            <StyledOtpStepBody direction={'column'} gap={6}>
                <InputText type={'text'} disabled={true} value={phone ? `+${maskPhoneNumber(String(phone))}` : '-'} />
            </StyledOtpStepBody>
        </StyledOtpStepWrapper>
    )
}

OtpSmsBaseStep.displayName = 'OtpSmsBaseStep'

import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {PromoBannerCustomProps} from '@/components/ui/promo-banner-atoms/PromoBannerAtoms'
import Container from '@/components/ui/container/Container'

export const StyledPromoBannerContainer = styled(Container)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            padding: 0;
        }
    `
)

export const StyledPromoBanner = styled(Flexbox)<{
    backgroundColor?: PromoBannerCustomProps['backgroundColor']
    textColor?: PromoBannerCustomProps['textColor']
}>(
    ({theme: {palette, spacing, mediaQueries}, backgroundColor, textColor}) => css`
        color: ${textColor ?? palette.neutral.black};
        background-color: ${backgroundColor ?? 'transparent'};
        position: relative;
        width: 100%;
        max-width: 1280px;
        border-radius: 16px;
        overflow: hidden;
        height: 132px;
        margin: ${spacing * 10}px auto;
        padding: ${spacing * 6}px ${spacing * 10}px;

        ${mediaQueries.l} {
            margin: 0 0 ${spacing * 8}px 0px;
        }

        ${mediaQueries.m} {
            height: auto;
            border-radius: 0;
        }
    `
)

export const StyledPromoBannerImage = styled.img(
    ({theme: {mediaQueries}}) => css`
        position: absolute;
        width: 650px;
        object-fit: cover;
        right: 0;
        z-index: 0;

        ${mediaQueries.m} {
            right: -50%;
        }
    `
)

export const StyledPromoBannerOverlay = styled.div(
    ({theme: {palette, mediaQueries}}) => css`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: linear-gradient(90deg, ${palette.neutral.black} 50%, transparent 65%);

        ${mediaQueries.m} {
            background: linear-gradient(90deg, ${palette.neutral.black} 65%, transparent 85%);
        }
    `
)

export const StyledPromoBannerContentWrapper = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        position: relative;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `
)

export const StyledPromoBannerIconContainer = styled.div(
    ({theme: {mediaQueries}}) => css`
        display: none;

        ${mediaQueries.m} {
            display: block;
            z-index: 1;
        }
    `
)

export const StyledPromoBannerNoteContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: end;
`

export const StyledPromoBannerTextContent = styled(Flexbox)(
    ({theme: {mediaQueries, spacing}}) => css`
        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing}px;
            margin-bottom: ${spacing * 4}px;
        }
    `
)

export const StyledPromoBannerTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.displayXs};
        font-weight: 500;
        max-width: 250px;
    `
)

export const StyledPromoBannerParagraph = styled.p(
    ({theme: {typography, spacing}}) => css`
        ${typography.textSm};
        & b {
            ${typography.displayXs};
            display: block;
            font-weight: 700;
        }

        & > sup {
            position: absolute;
            transform: translateY(-${spacing}px);
        }
    `
)

export const StyledPromoBannerCta = styled.div(
    ({theme: {mediaQueries}}) => css`
        flex-shrink: 0;
        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledPromoBannerNote = styled(Flexbox)(
    ({theme: {typography, spacing, mediaQueries}}) => css`
        max-width: 1280px;
        margin: 0 auto;
        ${typography.textXs};

        ${mediaQueries.m} {
            padding: 0 ${spacing * 8}px;
        }
    `
)

import Container from '@components/ui/container/Container.tsx'
import {
    StyledHeaderWrapper,
    StyledHeaderGrid,
    StyledMenuItems,
    StyledDropdownUserInfosItems
} from '@components/commons/header/style.ts'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import Logo from '@assets/images/logo_dark.svg'
import NavLink from '@components/ui/nav-link/NavLink.tsx'
import {ChangeLangModalTrigger} from '@components/commons/change-lang-modal-trigger/ChangeLangModalTrigger.tsx'
import DropdownMenu from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {useTranslation} from 'react-i18next'
import {Camera01Icon, CategoryIsland, LogoutIcon} from 'src/components/ui/icon'
import {useMemo, useState} from 'react'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {logout} from '@/features/sso/services/ssoAuthActions.ts'
import {generatePath, Link, useLocation, useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import AvatarPlaceholder from '@assets/images/avatar.jpg'
import {StyledSSOModal} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget.tsx'
import {useTheme} from 'styled-components'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export type MenuItem = {
    name: string
    path: string
}

interface HeaderProps {
    items: MenuItem[]
}

const Header = ({items}: HeaderProps) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    //to intercept dash params and perform a correct redirect on logo click
    const urlParams = useURLParsedParams(DashboardURLParams)
    const user = useAuthStore(state => state.user)
    const [showLangModal, setShowLangModal] = useState(false)
    const [showSSOModal, setShowSSOModal] = useState(false)
    const [showSSOCategoryModal, setShowSSOCategoryModal] = useState(false)
    const {pathname} = useLocation()
    const navigate = useNavigate()

    const logoRedirectLink = useMemo(() => {
        if (pathname.includes('/co-alarm')) {
            return routes.CO_ALARM_DASHBOARD.path
        } else if (pathname.includes('/categories')) {
            return routes.CATEGORIES.path
        } else if (urlParams.success) {
            return generatePath(routes.DASHBOARD.path, {
                hostCode: urlParams.data.hostCode,
                hostPassword: urlParams.data.hostPassword
            })
        } else {
            return routes.HOME.path
        }
    }, [pathname, urlParams])

    return (
        <StyledHeaderWrapper>
            {showLangModal && <LangSelectionModal onCloseCb={() => setShowLangModal(false)} />}
            {showSSOModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOModal(!showSSOModal)}>
                    <AirbnbSSOWidget
                        serviceIcon={<Camera01Icon size={40} fill={palette.neutral.black} />}
                        title={t('photoshoot_landing:sso:title')}
                        subtitle={t('photoshoot_landing:sso:subtitle')}
                        disclaimer={t('photoshoot_landing:sso:disclaimer')}
                    />
                </StyledSSOModal>
            )}

            {showSSOCategoryModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOCategoryModal(!showSSOCategoryModal)}>
                    <AirbnbSSOWidget
                        serviceIcon={<CategoryIsland size={40} fill={palette.neutral.black} />}
                        title={t('categories:sso:title')}
                        subtitle={t('categories:sso:subtitle')}
                        disclaimer={t('categories:sso:disclaimer')}
                        origin={routes.CATEGORIES.path}
                    />
                </StyledSSOModal>
            )}

            <Container>
                <StyledHeaderGrid>
                    <Link to={logoRedirectLink} aria-description={t('commons:logo_link')}>
                        <img src={Logo} alt={'Elevate'} height={28} />
                    </Link>
                    <StyledMenuItems>
                        {items.map(({name, path}) => (
                            <NavLink path={path} name={name} key={name} />
                        ))}
                    </StyledMenuItems>

                    <ChangeLangModalTrigger showCallback={() => setShowLangModal(!showLangModal)} />
                    {user ? (
                        <DropdownMenu
                            sideOffset={8}
                            trigger={<Avatar name={user.first_name ?? 'User'} imageUrl={user.picture_url ?? ''} />}
                            actions={[
                                {
                                    component: (
                                        <StyledDropdownUserInfosItems gap={2} align={'center'}>
                                            <Avatar
                                                size={'md'}
                                                name={user.first_name ?? 'User'}
                                                imageUrl={user.picture_url ?? ''}
                                            />
                                            <Flexbox direction={'column'}>
                                                <span>{user.first_name}</span>
                                                <small>{user.email}</small>
                                            </Flexbox>
                                        </StyledDropdownUserInfosItems>
                                    ),
                                    withSeparator: true
                                },
                                /*{
                                    component: t('coAlarm:title'),
                                    icon: <img src={CoAlarmLogoIcon} alt={'smoke-icon'} width={16} />,
                                    onClickCb: () => navigate(routes.CO_ALARM_REQUEST.path),
                                    disabled: pathname.includes(routes.CO_ALARM_REQUEST.path),
                                    withSeparator: true
                                },*/
                                /* {
                                    component: t('categories:title'),
                                    icon: <CategoryIsland size={16} />,
                                    onClickCb: () => navigate(routes.CATEGORIES.path),
                                    withSeparator: true
                                },*/
                                {
                                    component: t('commons:logout'),
                                    icon: <LogoutIcon size={16} />,
                                    onClickCb: logout
                                }
                            ]}
                        />
                    ) : (
                        !pathname.includes(routes.SSO_LOGIN.path) &&
                        !pathname.includes(routes.PHOTOSHOOT_LANDING.path) && (
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<Avatar name={'User'} imageUrl={AvatarPlaceholder} />}
                                actions={[
                                    /*{
                                        component: t('categories:title'),
                                        icon: <CategoryIsland size={16} />,
                                        onClickCb: () => setShowSSOCategoryModal(!showSSOCategoryModal),
                                        withSeparator: true
                                    },*/
                                    {
                                        component: t('commons:login'),
                                        onClickCb: () => navigate(routes.SSO_LOGIN.path),
                                        icon: <LogoutIcon size={16} />
                                    }
                                ]}
                            />
                        )
                    )}
                </StyledHeaderGrid>
            </Container>
        </StyledHeaderWrapper>
    )
}

export default Header

import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '@components/ui/button/Button.tsx'
import {OtpSmsProcess} from '@/features/otp-flow/components/otp-sms-process/OtpSmsProcess.tsx'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {
    StyledNotificationConsentBody,
    StyledNotificationConsentDesc,
    StyledNotificationConsentFooter
} from '@/features/service/components/notification-consent-modal/style.ts'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {getServiceType} from '@utilities/helpers.ts'

export const NotificationConsentModal: FC<{onCloseCb: () => void}> = ({onCloseCb}) => {
    const {t} = useTranslation()
    //otp store
    const optProcessStep = useOtpStore(state => state.step)
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    //modal composition utils
    const notifyEmailBodySubtitle = useMemo(() => {
        if (coAlarmRequest) {
            return t(AcceptanceStepsConfigByService['sms']['coAlarm'].subtitle)
        } else if (service) {
            return t(AcceptanceStepsConfigByService['sms'][serviceType].subtitle)
        } else {
            return ''
        }
    }, [service, coAlarmRequest])

    return (
        <>
            <StyledNotificationConsentBody direction={'column'} gap={6}>
                <StyledNotificationConsentDesc
                    direction={'column'}
                    gap={2}
                    disabled={optProcessStep !== 'base' && optProcessStep !== 'channel_toggle'}
                >
                    <h1>{t('service_details:text_notifications_modal:title')}</h1>
                    <p>{notifyEmailBodySubtitle}</p>
                </StyledNotificationConsentDesc>

                <OtpSmsProcess />
            </StyledNotificationConsentBody>

            <StyledNotificationConsentFooter justify={'center'}>
                <Button variant={'tertiary'} size={'md'} onClick={onCloseCb}>
                    {t('commons:close')}
                </Button>
            </StyledNotificationConsentFooter>
        </>
    )
}

NotificationConsentModal.displayName = 'OtpChannelToggleStep'

import InputText from '@/components/commons/input-text/InputText'
import Container from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledCategoriesContainer = styled(Container)`
    height: auto;
`

export const StyledPageHeaderWrapper = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            flex-direction: column;
            align-items: flex-start;
        }
    `
)

export const StyledCategoriesInputTextWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 16}px 0 ${spacing * 8}px;

        ${mediaQueries.m} {
            padding: 0;
            width: 100%;
        }
    `
)

export const StyledCategoriesInputText = styled(InputText)(
    ({theme: {mediaQueries}}) => css`
        width: 320px;

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledReadMoreLink = styled.a`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
`

export const StyledListingCategoriesWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        align-items: start;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 8}px;
        justify-items: center;
        width: 100%;

        ${mediaQueries.l} {
            grid-template-columns: 1fr;
            padding: ${spacing * 8}px 0;
        }
    `
)

export const StyledButtonWrapper = styled.div(
    ({theme: {mediaQueries}}) => css`
        grid-column: span 2;

        ${mediaQueries.m} {
            grid-column: span 1;
        }
    `
)

export const StyledCategoriesRequestModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    max-height: 750px;
`

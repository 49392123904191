import {z} from 'zod'

export const CO_ALARM_EDIT_REQUEST_MODEL = {
    firstName: {
        name: 'first_name',
        label: 'coAlarm:form:first_name',
        defaultValue: '',
        placeholder: 'coAlarm:form:first_name_placeholder',
        helpText: ''
    },
    lastName: {
        name: 'last_name',
        label: 'coAlarm:form:last_name',
        defaultValue: '',
        placeholder: 'coAlarm:form:last_name_placeholder',
        helpText: ''
    },
    country: {
        name: 'country',
        label: 'coAlarm:form:country',
        defaultValue: [],
        placeholder: 'coAlarm:form:country_placeholder',
        helpText: 'coAlarm:form:country_help_text'
    },
    phone: {
        name: 'phone',
        label: 'coAlarm:form:phone',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:phone_placeholder',
        helpText: ''
    },
    firstAddressLine: {
        name: 'address_line_1',
        label: 'coAlarm:form:manual_address_modal:address_line_1',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:address_line_placeholder'
    },
    secondAddressLine: {
        name: 'address_line_2',
        label: 'coAlarm:form:manual_address_modal:address_line_2',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:address_line_placeholder'
    },
    city: {
        name: 'city',
        label: 'coAlarm:form:manual_address_modal:city',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:city_placeholder'
    },
    state: {
        name: 'state',
        label: 'coAlarm:form:manual_address_modal:state',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:state_placeholder'
    },
    postalCode: {
        name: 'postal_code',
        label: 'coAlarm:form:manual_address_modal:postal_code',
        defaultValue: '',
        placeholder: 'coAlarm:form:manual_address_modal:postal_code_placeholder'
    },
    addressExtras: {
        name: 'address_extras',
        label: 'coAlarm:form:address_extras',
        defaultValue: '',
        placeholder: 'coAlarm:form:address_extras_placeholder',
        helpText: ''
    }
} as const

export const CoAlarmEditRequestSchema = z.object({
    manual_address: z.boolean(),
    first_name: z.string().min(1, {message: 'coAlarm:form:first_name_error'}),
    last_name: z.string().min(1, {message: 'coAlarm:form:last_name_error'}),
    country: z.object({
        icon: z.unknown(),
        label: z.string(),
        value: z.string(),
        code: z.string()
    }),
    address_line_1: z.string().min(3, {message: 'coAlarm:form:manual_address_modal:address_line_1_error'}),
    address_line_2: z.string().nullish(),
    city: z.string().min(1, {message: 'coAlarm:form:manual_address_modal:city_error'}),
    state: z.string().min(1, {message: 'coAlarm:form:manual_address_modal:state_error'}),
    postal_code: z.string().min(1, {message: 'coAlarm:form:manual_address_modal:postal_code_error'}),
    phone: z.string().min(8, {message: 'coAlarm:form:phone_error'}),
    address_extras: z.string().nullish()
})

export type CoAlarmEditRequestSchema = z.infer<typeof CoAlarmEditRequestSchema>

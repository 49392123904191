import InputSelect from '@/components/commons/select/Select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCoAlarmRequestFormWrapper = styled(Flexbox)`
    max-width: 600px;
    width: 100%;

    & input::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }
`

export const StyledCoAlarmRequestFormSection = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h4 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledListingSelector = styled(InputSelect)(
    ({theme: {palette, typography}}) => css`
        width: 100%;

        & .select__control {
            height: 68px;
        }

        & .select__control .select__single-value {
            color: ${palette.neutral[900]};
            ${typography.textMd};
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
        }
    `
)

export const StyledCoAlarmRequestSentMessage = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};

        & > h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & > p {
            color: ${palette.neutral['700']};
        }
    `
)

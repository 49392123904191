import {FC, ReactNode, useState} from 'react'
import Button from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {AirbnbIcon} from 'src/components/ui/icon'
import {envVars} from '@/envVars.ts'
import {StyledInfos, StyledSSOBox, StyledSSOWrapper} from '@/features/sso/styles.ts'
import {DefaultNamespace} from 'i18next'

interface AirbnbSSOWidgetProps {
    serviceIcon?: ReactNode
    title: string | DefaultNamespace
    subtitle?: string | DefaultNamespace
    disclaimer?: string | DefaultNamespace | ReactNode
    origin?: string
}

export const AirbnbSSOWidget: FC<AirbnbSSOWidgetProps> = ({serviceIcon, title, subtitle, disclaimer, origin}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    const airbnbLoginHandler = () => {
        setIsLoading(true)

        if (origin) {
            localStorage.setItem('elevate-login-origin', origin)
        }

        window.location.href = `https://www.airbnb.com/oauth2/auth?client_id=${envVars.VITE_AIRBNB_APP_ID}&redirect_uri=${envVars.VITE_AIRBNB_DATA_REDIRECT_URI}&scope=${envVars.VITE_AIRBNB_DATA_SCOPE}&state`
    }

    return (
        <StyledSSOWrapper>
            <StyledSSOBox>
                {serviceIcon}
                <StyledInfos>
                    {title && <h1>{title}</h1>}
                    {subtitle && <p>{subtitle}</p>}
                </StyledInfos>
                <Button
                    variant={'primary'}
                    id={'airbnbLogin'}
                    size={'md'}
                    onClick={airbnbLoginHandler}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Spinner size={18} color={palette.neutral.white} />
                    ) : (
                        <>
                            <AirbnbIcon />
                            {t('commons:sso_cta_label')}
                        </>
                    )}
                </Button>
                {disclaimer && <small>{disclaimer}</small>}
            </StyledSSOBox>
        </StyledSSOWrapper>
    )
}

AirbnbSSOWidget.displayName = 'AirbnbSSOWidget'

import ReactSelect, {ActionMeta, InputActionMeta, MultiValue, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customSelectStyles, StyledContainer} from './style.ts'
import {useTheme} from 'styled-components'
import {Label} from '@components/ui/label/Label.tsx'
import {FocusEventHandler, ReactNode, RefCallback, useCallback} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {customComponents} from '@components/commons/select/commons.tsx'
import {DefaultNamespace} from 'i18next'

export type SelectValue = {
    label: string | ReactNode | unknown
    value: string
    icon?: ReactNode
}

export interface SelectProps {
    className?: string
    name?: string
    options: SelectValue[]
    label?: string | DefaultNamespace
    placeholder?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    readOnly?: boolean
    defaultValue?: SelectValue | []
    isSearchable?: boolean
    isClearable?: boolean
    isCreatable?: boolean
    isLoading?: boolean
    formatCreateLabel?: (value: string) => ReactNode
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
    onMenuScrollToBottom?: () => void
    onBlur?: FocusEventHandler<HTMLInputElement>
    onFocus?: FocusEventHandler<HTMLInputElement>
    addOptionMessage?: string
    maxItems?: number
    isMulti?: boolean
    closeMenuOnSelect?: boolean
    disabled?: boolean
    size?: 'medium' | 'large'
    selectAriaLabel?: string
    /**
     * How to use the onChange based on controlled or uncontrolled select:
     *
     * uncontrolled: onChange={(event) => console.log(event)}
     * controlled multi: onChange={(newValue) => {onChange(newValue as SelectValue[])}}
     * controlled single: onChange={(newValue) => {onChange([newValue] as SelectValue[])}}
     */
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
        actionMeta: ActionMeta<SelectValue>
    ) => void
    value?: SelectValue | SelectValue[]
    ref?: RefCallback<unknown>
    errorMessage?: string | DefaultNamespace
}

const InputSelect = ({
    className,
    name,
    options = [],
    label,
    placeholder,
    helpText,
    defaultValue,
    isSearchable = false,
    isCreatable = false,
    addOptionMessage = '',
    maxItems = 100,
    isMulti = false,
    closeMenuOnSelect = !isMulti,
    disabled,
    onChange,
    errorMessage,
    selectAriaLabel,
    ...rest
}: SelectProps) => {
    const theme = useTheme()

    // Label for new item creation
    const createLabel = useCallback(
        (value: string) => (
            <span style={{fontSize: 14}}>
                {addOptionMessage}
                <span>{value}</span>
            </span>
        ),
        [addOptionMessage]
    )

    const selectProps = {
        options,
        name,
        closeMenuOnSelect,
        isSearchable,
        isCreatable,
        isMulti,
        isDisabled: rest.readOnly || disabled,
        classNamePrefix: isCreatable ? 'creatable_select' : 'select',
        placeholder,
        createLabel,
        disabled,
        maxItems,
        defaultValue,
        onChange,
        ...rest
    }

    const selectComponentProps = {
        formatCreateLabel: createLabel,
        components: customComponents,
        ...selectProps,
        styles: customSelectStyles({theme, isError: !!errorMessage})
    }

    return (
        <StyledContainer className={className}>
            {label && <Label htmlFor={name}>{label}</Label>}
            {isCreatable ? (
                <CreatableSelect {...selectComponentProps} />
            ) : (
                <ReactSelect {...selectComponentProps} aria-label={selectAriaLabel ?? label} />
            )}
            <InputHelpText helpText={helpText} error={errorMessage} />
        </StyledContainer>
    )
}

export default InputSelect

InputSelect.displayName = 'InputSelect'

import styled, {css} from 'styled-components'

export const StyledSSOWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 1fr;
        justify-items: center;
        padding: ${spacing * 24}px 0;
        height: 100%;

        ${mediaQueries.m} {
            padding: ${spacing * 12}px 0;
        }
    `}
`

export const StyledSSOBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 455px;
        gap: ${spacing * 6}px;
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['700']};
            & a {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    `}
`

export const StyledInfos = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: ${spacing * 4}px;
        & > h1 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }
        & > p {
            ${typography.textLg};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }
    `}
`

import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledLangModalToggle} from '@components/commons/change-lang-modal-trigger/style.ts'
import {APP_LANGUAGES} from '@/translations/i18n.ts'
import {Globe02Icon} from 'src/components/ui/icon'

export const ChangeLangModalTrigger: FC<{showCallback?: () => void}> = ({showCallback}) => {
    const {
        i18n: {language, t}
    } = useTranslation()

    return (
        <StyledLangModalToggle role="button" aria-label={t('commons:change_lang')} onClick={showCallback}>
            <Globe02Icon width={20} />
            <span>{APP_LANGUAGES.find(lang => lang.value == language)?.label ?? language}</span>
        </StyledLangModalToggle>
    )
}

ChangeLangModalTrigger.displayName = 'ChangeLangModalTrigger'

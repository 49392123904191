import InputText from '@/components/commons/input-text/InputText'
import InputSelect from '@/components/commons/select/Select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledCoAlarmSendRequestInputsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `}
`

export const GooglePlacesAutocompleteWrapper = styled(Flexbox)`
    & div[class*='-container'] {
        width: 100%;
    }

    & div[class*='-control'] {
        min-height: unset;
        height: 40px;
    }
`

export const StyledFormattedAddress = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        & strong {
            ${typography.textSm};
            margin-right: ${spacing * 2}px;
            font-weight: 500;
            color: ${palette.neutral['700']};
        }
        & span {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `}
`

export const StyledManualAddressFormHeader = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 6}px;
        color: ${palette.neutral[900]};

        h3 {
            ${typography.textXl};
            font-weight: 500;
        }
    `
)

export const StyledManualAddressFormContainer = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
        overflow: auto;
        height: 100%;

        & input::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            position: absolute;
            right: 0;
        }
    `}
`

export const StyledManualAddressLocationWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: ${spacing * 2}px;
    `}
`

export const StyledCoAlarmRequestModal = styled(Modal)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;
        max-height: calc(100% - ${spacing * 4}px);
    `
)

export const StyledCoManualAddressFormFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral[300]};
    `
)

export const StyledCoAlarmRequestSentMessage = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};

        & > h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & > p {
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledSelectCountry = styled(InputSelect)`
    width: 100%;

    & .select__control {
        min-height: 40px;
    }
`

export const StyledFullWidthInputText = styled(InputText)`
    width: 100%;
`

export const StyledPhoneInputWrapper = styled(Flexbox)`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        width: 100%;
        .react-tel-input > input {
            ${typography.textMd}
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing}px;
            border: 1px solid ${palette.neutral['300']};
            width: 100%;
            transition: ease-in-out 200ms;
            cursor: pointer;
            height: 40px;
            padding: 0 ${spacing * 9}px 0 ${spacing * 12}px;
            border-radius: 8px;
            &:not(:disabled):hover {
                border: 1px solid ${palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['25']}`};
            }
            &:focus {
                outline: none;
                border-color: ${palette.neutral['300']};
                box-shadow: unset;
            }
            &::placeholder {
                color: ${palette.neutral['400']};
            }
            &:disabled {
                cursor: not-allowed;
            }
        }
    `}
`

import {
    PromoBannerContainer,
    PromoBannerContentWrapper,
    PromoBannerCta,
    PromoBannerNote,
    PromoBannerParagraph,
    PromoBannerTextContent,
    PromoBannerTitle
} from '@/components/ui/promo-banner-atoms/PromoBannerAtoms'
import {useTheme} from 'styled-components'
import {Trans, useTranslation} from 'react-i18next'
import {Camera01Icon} from '@/components/ui/icon'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuthStore} from '@/store/AuthStore'
import {StyledCoAlarmPromoBanner, StyledCoAlarmPromoBannerCtaLink} from './style'

export const CoAlarmPromoBanner = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width['m']
    const user = useAuthStore(state => state.user)

    return (
        <>
            <PromoBannerContainer>
                <StyledCoAlarmPromoBanner backgroundColor={palette.neutral['100']}>
                    <PromoBannerContentWrapper>
                        <PromoBannerTextContent>
                            <PromoBannerTitle>{t('photoshoot_promo_banner:title')}</PromoBannerTitle>
                            <PromoBannerParagraph>
                                <Trans i18nKey={'photoshoot_promo_banner:subtitle'} components={[<b />, <sup />]} />
                            </PromoBannerParagraph>
                        </PromoBannerTextContent>
                        {user && (
                            <PromoBannerCta>
                                <StyledCoAlarmPromoBannerCtaLink
                                    variant="secondary"
                                    size="lg"
                                    fullWidth={isMobile}
                                    to={{
                                        pathname: generatePath(routes.PHOTOSHOOT_REQUEST.path),
                                        search: new URLSearchParams({source: 'co-alarm'}).toString()
                                    }}
                                >
                                    <Camera01Icon />
                                    {t('photoshoot_promo_banner:button')}
                                </StyledCoAlarmPromoBannerCtaLink>
                            </PromoBannerCta>
                        )}
                    </PromoBannerContentWrapper>
                </StyledCoAlarmPromoBanner>
            </PromoBannerContainer>

            <PromoBannerContainer>
                <PromoBannerNote gap={1}>
                    <Trans i18nKey={'photoshoot_promo_banner:note'} components={[<sup>1</sup>]} />
                </PromoBannerNote>
            </PromoBannerContainer>
        </>
    )
}

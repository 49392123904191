import {HTMLAttributes} from 'react'
import {FlexBoxCustomProps} from '@/components/ui/flexbox/FlexBox'
import {
    StyledPromoBanner,
    StyledPromoBannerParagraph,
    StyledPromoBannerTitle,
    StyledPromoBannerContentWrapper,
    StyledPromoBannerImage,
    StyledPromoBannerOverlay,
    StyledPromoBannerCta,
    StyledPromoBannerContainer,
    StyledPromoBannerTextContent,
    StyledPromoBannerIconContainer,
    StyledPromoBannerNoteContainer,
    StyledPromoBannerNote
} from '@/components/ui/promo-banner-atoms/style'
import photoshootBannerBg from '@assets/images/photoshoot_banner_bg.jpg'
import {ContainerProps} from '../container/Container'

export type PromoBannerCustomProps = {
    textColor?: string
    backgroundColor?: string
}

// Containers & wrappers
export const PromoBannerContainer = (props: ContainerProps) => <StyledPromoBannerContainer {...props} />
export const PromoBanner = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps & PromoBannerCustomProps) => (
    <StyledPromoBanner direction="column" justify="center" gap={2} {...props} />
)
export const PromoBannerContentWrapper = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps) => (
    <StyledPromoBannerContentWrapper width={'100%'} align="center" {...props} />
)
export const PromoBannerIconContainer = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledPromoBannerIconContainer {...props} />
)

export const PromoBannerNoteContainer = (props: ContainerProps) => <StyledPromoBannerNoteContainer {...props} />

// Image atoms
export const PromoBannerBackgroundImage = (props: HTMLAttributes<HTMLImageElement> & {bannerBg?: string}) => (
    <StyledPromoBannerImage src={props.bannerBg ?? photoshootBannerBg} {...props} />
)
export const PromoBannerOverlay = (props: HTMLAttributes<HTMLDivElement>) => <StyledPromoBannerOverlay {...props} />

// Content components
export const PromoBannerTextContent = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps) => (
    <StyledPromoBannerTextContent gap={10} width={'100%'} {...props} />
)
export const PromoBannerTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledPromoBannerTitle {...props} />
export const PromoBannerParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledPromoBannerParagraph {...props} />
)
export const PromoBannerCta = (props: HTMLAttributes<HTMLDivElement>) => <StyledPromoBannerCta {...props} />

export const PromoBannerNote = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps) => (
    <StyledPromoBannerNote {...props} />
)

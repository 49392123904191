import {useTranslation} from 'react-i18next'
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react'
import {StyledCoAlarmRequestFormSection, StyledCoAlarmRequestFormWrapper, StyledListingSelector} from './style'
import {debounce} from '@/utilities/helpers'
import {CoAlarmListingRemappedRequest} from '@/features/co-alarm/types'
import {REDUCED_FORM_COUNTRIES} from '@/utilities/constants/countries'
import {SelectValue} from '@/components/commons/select/Select'
import {CoAlarmSendRequestExtendedForm} from '@/features/co-alarm/components/co-alarm-send-request-form/extended-form/CoAlarmSendRequestExtendedForm'
import {CoAlarmSendRequestInfo} from '@/features/co-alarm/components/co-alarm-send-request-info/CoAlarmSendRequestInfo'
import {CoAlarmSendRequestForm} from '@/features/co-alarm/components/co-alarm-send-request-form/form/CoAlarmSendRequestForm'
import {ListingThumbnailImage} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'

type CoAlarmSelectedListing = CoAlarmListingRemappedRequest | null | undefined

export const CoAlarmSendRequestFormWrapper = ({
    listings,
    onSearchListing,
    isChangingListingsPage,
    isPendingListings,
    onChangePage
}: {
    listings: CoAlarmListingRemappedRequest[]
    onSearchListing: Dispatch<SetStateAction<string | null>>
    isChangingListingsPage: boolean
    isPendingListings: boolean
    onChangePage: () => void
}) => {
    const {t} = useTranslation()
    const [selectedListing, setSelectedListing] = useState<CoAlarmSelectedListing>(
        listings.length == 1 ? listings[0] : null
    )

    const [selectedCountry, setSelectedCountry] = useState<string | null>(null)

    const listingsOptions = useMemo(
        () =>
            listings.map(listing => ({
                value: listing.name.toLowerCase(),
                label: listing.name,
                icon: <ListingThumbnailImage aria-hidden={true} src={listing.image_url} />
            })),
        [listings]
    )

    const isReducedForm = useMemo(() => {
        if (selectedCountry) {
            return REDUCED_FORM_COUNTRIES.includes(selectedCountry.toLowerCase())
        }

        if (selectedListing) {
            return REDUCED_FORM_COUNTRIES.includes(selectedListing.country_code.toLowerCase())
        }

        return false
    }, [selectedCountry, selectedListing])

    const onSearch = debounce((value: string) => {
        onSearchListing(value)
    }, 750)

    useEffect(() => {
        setSelectedCountry(null)
    }, [selectedListing])

    return (
        <StyledCoAlarmRequestFormWrapper direction="column" gap={8}>
            <StyledCoAlarmRequestFormSection direction="column" gap={6} width="100%">
                <h4>{t('coAlarm:form:listing')}</h4>
                <StyledListingSelector
                    options={listingsOptions}
                    onInputChange={value => onSearch(value)}
                    onMenuScrollToBottom={onChangePage}
                    isLoading={isPendingListings || isChangingListingsPage}
                    size={'medium'}
                    isSearchable={true}
                    placeholder={t('commons:search_listing_placeholder')}
                    selectAriaLabel={t('commons:search_listing_placeholder')}
                    value={
                        selectedListing
                            ? {
                                  value: selectedListing.name,
                                  label: selectedListing.name,
                                  icon: <ListingThumbnailImage aria-hidden={true} src={selectedListing.image_url} />
                              }
                            : undefined
                    }
                    onChange={newValue => {
                        setSelectedListing(() =>
                            newValue
                                ? listings.find(
                                      listing => listing.name.toLowerCase() == (newValue as SelectValue).value
                                  )
                                : null
                        )
                    }}
                />

                {selectedListing && (
                    <>
                        <h4>{t('coAlarm:form:shipping_info_title')}</h4>
                        {isReducedForm ? (
                            <CoAlarmSendRequestForm
                                selectedListing={selectedListing}
                                setSelectedCountry={setSelectedCountry}
                                selectedCountry={selectedCountry}
                            />
                        ) : (
                            <CoAlarmSendRequestExtendedForm
                                selectedListing={selectedListing}
                                setSelectedCountry={setSelectedCountry}
                                selectedCountry={selectedCountry}
                            />
                        )}
                    </>
                )}
            </StyledCoAlarmRequestFormSection>

            <CoAlarmSendRequestInfo />
        </StyledCoAlarmRequestFormWrapper>
    )
}

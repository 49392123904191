import {serviceStepsDescriptions} from '@/features/service/utils.tsx'
import {FreelancerCard} from '@/features/service/components/freelancer-card/FreelancerCard.tsx'
import {StyledStatusWrapper} from '@/features/service/components/service-status-wrapper/style.ts'
import {useServiceDetails} from '@/features/service/services/queries/useServiceDetails.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {getServiceType, raise} from '@utilities/helpers.ts'

export const ServiceStatusWrapper = () => {
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const serviceQuery = useServiceDetails(
        {
            urlParams,
            params: {response_type: 'extended'}
        },
        {retry: false}
    )

    if (!serviceQuery.data) {
        return <Spinner size={40} />
    }

    const serviceType = getServiceType(serviceQuery.data.task_media.name, serviceQuery.data.task_category.name)
    const canSeePhotographerCard =
        !!serviceQuery.data.assignment?.photographer &&
        (['accepted', 'assets_in_review', 'needs_more_assets', 'pending_assets', 'scheduled'].includes(
            serviceQuery.data.assignment.task_status.status
        ) ||
            (serviceQuery.data.assignment.task_status.status == 'pending_payment' &&
                serviceQuery.data.assignment?.task_status.post_prod_status != 'upload_completed'))

    return (
        <StyledStatusWrapper>
            {serviceStepsDescriptions[serviceType]({
                addedNewImages: serviceQuery.data.added_new_images,
                assignedFreelancer: serviceQuery.data.assignment?.photographer?.first_name,
                assignmentDate: serviceQuery.data.assignment?.to_shoot_at,
                assignmentStatus: serviceQuery.data.assignment?.status,
                cancellationRequestedAt: serviceQuery.data.assignment?.cancellation_requested_at,
                coordinationStatus: serviceQuery.data.assignment?.task_status.coordination_status,
                hostUnresponsiveAt: serviceQuery.data.assignment?.host_unresponsive_at,
                manualCoordinationAt: serviceQuery.data.assignment?.manual_coordination_at,
                hasSharedDates: !!serviceQuery.data.actions.find(action => action.key_name == 'set_date')?.value,
                isFeedbackSent: !!serviceQuery.data.quote.raw_satisfaction,
                postProdStatus: serviceQuery.data.assignment?.task_status.post_prod_status,
                quoteStatus: serviceQuery.data.quote.quote_status,
                reminderAt: serviceQuery.data.assignment?.reminder_at,
                taskCategory: serviceQuery.data.task_category.name,
                taskMedia: serviceQuery.data.task_media.name,
                taskStatus: serviceQuery.data.assignment?.task_status.status
            })}
            {canSeePhotographerCard && (
                <FreelancerCard
                    freelancer={serviceQuery.data.assignment?.photographer ?? raise('The freelancer does not exist')}
                    serviceType={serviceType}
                />
            )}
        </StyledStatusWrapper>
    )
}

ServiceStatusWrapper.displayName = 'ServiceDescription'

import styled, {css} from 'styled-components'

export const StyledListingThumbnailImage = styled.img`
    width: 64px;
    height: 44px;
    object-fit: cover;
    border-radius: 8px;
    flex-shrink: 0;
`

export const StyledListingThumbnailName = styled.span(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[900]};
        ${typography.textMd};
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;

        a {
            cursor: pointer;
            text-decoration: underline;
        }
    `
)

export const StyledListingThumbnailParagraph = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[600]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        ${typography.textSm};
    `
)
